import { MDText } from 'i18n-react';

import { INSTANT_MATCH_URL, YOUTUBE_EMBED_URL } from 'Utilities/urls';

import cvtAvif from '../../../../../../client/assets_seller/homepage/car-valuation.avif';
import cvtPng from '../../../../../../client/assets_seller/homepage/car-valuation.png';
import cvtWebp from '../../../../../../client/assets_seller/homepage/car-valuation.webp';
import appAvif from '../../../../../../client/assets_seller/homepage/download-app.avif';
import appPng from '../../../../../../client/assets_seller/homepage/download-app.png';
import appWebp from '../../../../../../client/assets_seller/homepage/download-app.webp';
import instantMatchAvif from '../../../../../../client/assets_seller/homepage/instant-match-car.avif';
import instantMatchPng from '../../../../../../client/assets_seller/homepage/instant-match-car.png';
import instantMatchWebp from '../../../../../../client/assets_seller/homepage/instant-match-car.webp';
import advertAvif from '../../../../../../client/assets_seller/homepage/motorway-tv-ad.avif';
import advertJpg from '../../../../../../client/assets_seller/homepage/motorway-tv-ad.jpg';
import advertWebp from '../../../../../../client/assets_seller/homepage/motorway-tv-ad.webp';
import LocalTexts from '../../HomepageTexts.json';

import { NewsAndToolsCardProps } from './SubComponents/NewsAndToolsCard/NewsAndToolsCard';

const LocalT = new MDText(LocalTexts);

export const NEWS_AND_TOOLS_TILES = {
  ADVERTISEMENT: 'advert',
  APP: 'app',
  CVT: 'cvt',
  INSTANT_MATCH: 'instantMatch',
  ULEZ: 'ulez',
} as const;

export const newsAndToolsTilesContent: Omit<NewsAndToolsCardProps, 'onClick' | 'isSectionVisible'>[] = [
  {
    cta: LocalT.translate('NewsAndTools.advert.cta') as string,
    img: {
      alt: LocalT.translate('NewsAndTools.advert.imageAlt') as string,
      isBackgroundImage: true,
      size: {
        height: '100%',
        width: '100%',
      },
      src: {
        avif: advertAvif,
        png: advertJpg,
        webp: advertWebp,
      },
    },
    label: LocalT.translate('NewsAndTools.advert.label') as string,
    link: YOUTUBE_EMBED_URL,
    openInNewTab: true,
    title: LocalT.translate('NewsAndTools.advert.title') as string,
    type: NEWS_AND_TOOLS_TILES.ADVERTISEMENT,
  },
  {
    cta: LocalT.translate('NewsAndTools.cvt.cta') as string,
    img: {
      alt: LocalT.translate('NewsAndTools.cvt.imageAlt') as string,
      size: {
        height: 175,
        width: 80,
      },
      src: {
        avif: cvtAvif,
        png: cvtPng,
        webp: cvtWebp,
      },
    },
    label: LocalT.translate('NewsAndTools.cvt.label') as string,
    link: '/car-value-tracker',
    title: LocalT.translate('NewsAndTools.cvt.title') as string,
    type: NEWS_AND_TOOLS_TILES.CVT,
  },
  {
    cta: LocalT.translate('NewsAndTools.app.cta') as string,
    img: {
      alt: LocalT.translate('NewsAndTools.app.imageAlt') as string,
      size: {
        height: 155,
        width: 135,
      },
      src: {
        avif: appAvif,
        png: appPng,
        webp: appWebp,
      },
    },
    label: LocalT.translate('NewsAndTools.app.label') as string,
    link: 'https://motorway.onelink.me/gYoi/2jk6739s',
    openInNewTab: true,
    title: LocalT.translate('NewsAndTools.app.title') as string,
    type: NEWS_AND_TOOLS_TILES.APP,
  },
  {
    cta: LocalT.translate('NewsAndTools.instantMatch.cta') as string,
    img: {
      alt: LocalT.translate('NewsAndTools.instantMatch.imageAlt') as string,
      size: {
        height: 150,
        width: 100,
      },
      src: {
        avif: instantMatchAvif,
        png: instantMatchPng,
        webp: instantMatchWebp,
      },
    },
    label: LocalT.translate('NewsAndTools.instantMatch.label') as string,
    link: `${INSTANT_MATCH_URL}?utm_source=mw&utm_medium=news_widget`,
    openInNewTab: true,
    title: LocalT.translate('NewsAndTools.instantMatch.title') as string,
    trackingLink: INSTANT_MATCH_URL,
    type: NEWS_AND_TOOLS_TILES.INSTANT_MATCH,
  },
];
